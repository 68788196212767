import IndustryTrends from '@components/ROI/IndustryTrends/index';
import ButtonLayout from '@components/ROI/Layouts/Button/index';
import { GTM } from '@src/analytics/gtm';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';

import React, { useContext } from 'react';

export default function SalaryInsightFooter({ openDialog }) {
  const state = useContext(GlobalStateContext);
  return (
    <div className="hidden md:block">
      <ButtonLayout
        btnText={'See Industry Trends >'}
        className="w-[270px] border-2 border-solid border-v5-neutral-500 bg-v5-neutral-100 font-manrope"
        onClick={() => {
          openDialog({
            children: <IndustryTrends />,
          });
          GTM.leadSquaredEvent(
            ELeadSquaredActivityCode.ROI_SEE_INDUSTRY_TRENDS_CTA_CLICKED,
            state?.email,
          );
        }}
      />
    </div>
  );
}
