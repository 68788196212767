import { INPUT_FIELDS } from '@src/constants/ROI/index';

import { EMI_MONTH_INTEREST_MAPPING } from '@src/constants/ROI/index';

export let currentCTC = 800000;
export let newCTC = 15.2;
let timeToRecoverProgramFees = 5;
let monthlyEMI = 5000;
let roiPercentage = 125;

const EARNING_POTENTIAL_FACTOR_WORKING = Math.pow(1 + 0.12, 5);
const EARNING_POTENTIAL_FACTOR_NON_WORKING = 0.4;

const isValidField = (fieldName, data) => {
  const fieldInput = INPUT_FIELDS.find(({ name }) => fieldName === name);
  const minValue = fieldInput.minValue;
  const maxValue = fieldInput.maxValue;
  const multiple = fieldInput?.multiple;

  if (multiple) {
    return (
      data >= minValue && data <= maxValue && multiple && data % multiple === 0
    );
  } else {
    return data >= minValue && data <= maxValue;
  }
};

export const resetToDefauls = (workingStatus) => {
  currentCTC = workingStatus === 'working' ? 800000 : 0;
  newCTC = 15.2;
  timeToRecoverProgramFees = 5;
  monthlyEMI = 5000;
  roiPercentage = 125;
};

const isWorkingandHasValidFields = (data) => {
  return (
    data.currentWorkingStatus === 'working' &&
    isValidField('currentCTC', Number(data.currentCTC)) &&
    isValidField('priceOfProgram', Number(data.priceOfProgram))
  );
};

const isNotWorkingOrGraduated = (data) => {
  return (
    (data.currentWorkingStatus === 'notWorking' ||
      data.currentWorkingStatus === 'graduated') &&
    isValidField('priceOfProgram', Number(data.priceOfProgram)) &&
    isValidField('expectedSalary', Number(data.expectedSalary))
  );
};

const convertToLakhs = (num) => {
  return (num / 100000).toFixed(1);
};

const calculateHikedSalary = (currentCTC, hikePercentage) => {
  const salaryHikeAmount = hikePercentage * 0.01 * currentCTC;
  const salaryAfterHike = currentCTC + salaryHikeAmount;

  const salaryAfterHikeInLakhs = convertToLakhs(salaryAfterHike);

  newCTC = Number(salaryAfterHikeInLakhs) ? salaryAfterHikeInLakhs : newCTC;
  return { salaryHikeAmount, salaryAfterHike, salaryAfterHikeInLakhs };
};

const calculateSalaryHikeNonWorking = (currentCTC, expectedSalary) => {
  const expectedSalaryInLakhs = (expectedSalary / 100000).toFixed(1);
  newCTC = Number(expectedSalaryInLakhs) ? expectedSalaryInLakhs : newCTC;
};

const calculateTimeToRecoverProgramFees = (
  priceOfProgram,
  salaryHikeAmount,
) => {
  const perMonthSalaryHike = salaryHikeAmount / 12;
  timeToRecoverProgramFees =
    Math.ceil(priceOfProgram / perMonthSalaryHike) || timeToRecoverProgramFees;
};

const calculateMonthlyEMI = (emiDuration, priceOfProgram) => {
  const interestFactor = EMI_MONTH_INTEREST_MAPPING[emiDuration];
  const monthlyEMIFloating =
    emiDuration === 0
      ? priceOfProgram
      : (priceOfProgram * interestFactor + priceOfProgram) / emiDuration ||
        monthlyEMI;
  monthlyEMI = parseInt(monthlyEMIFloating);
  return { monthlyEMIFloating };
};

const calculateROIPercentage = (
  monthlyEMIFloating,
  emiDuration,
  salaryAfterHike,
) => {
  const effectiveProgramCost =
    monthlyEMIFloating * (emiDuration === 0 ? 1 : emiDuration);

  const earningPotentialWithoutProgram =
    Number(currentCTC) * EARNING_POTENTIAL_FACTOR_WORKING;

  const earningPotentialWithProgram =
    salaryAfterHike * EARNING_POTENTIAL_FACTOR_WORKING;

  const totalReturn =
    earningPotentialWithProgram - earningPotentialWithoutProgram;

  roiPercentage = Math.ceil(
    ((totalReturn - effectiveProgramCost) / effectiveProgramCost) * 100,
  );
};

const calculateROIPercentageNonWokring = (
  expectedSalary,
  monthlyEMIFloating,
  emiDuration,
) => {
  const totalReturn =
    expectedSalary + expectedSalary * EARNING_POTENTIAL_FACTOR_NON_WORKING;

  const effectiveProgramCost =
    monthlyEMIFloating * (emiDuration === 0 ? 1 : emiDuration);

  roiPercentage = Math.ceil(
    ((totalReturn - effectiveProgramCost) / effectiveProgramCost) * 100,
  );
};

export const performCalculations = (data) => {
  const priceOfProgram = Number(data.priceOfProgram);
  const hikePercentage = Number(data.hikePercentage?.at(0));
  const emiDuration = Number(data.emiDuration?.at(0));
  const expectedSalary = Number(data.expectedSalary);

  //If dropdown selected is working:
  if (isWorkingandHasValidFields(data)) {
    //Calculate Hiked Salary:
    currentCTC = Number(data.currentCTC);
    const { salaryHikeAmount, salaryAfterHike } = calculateHikedSalary(
      currentCTC,
      hikePercentage,
    );

    //calculate time to recover program fees:
    calculateTimeToRecoverProgramFees(priceOfProgram, salaryHikeAmount);

    //Calculate monthly emi amount:
    const { monthlyEMIFloating } = calculateMonthlyEMI(
      emiDuration,
      priceOfProgram,
    );

    //Calculate ROI percentage:
    calculateROIPercentage(monthlyEMIFloating, emiDuration, salaryAfterHike);
  } else if (isNotWorkingOrGraduated(data)) {
    //If dropdown selected is "not working" or "just graduated":

    //Calculate hiked CTC:
    currentCTC = 0;
    calculateSalaryHikeNonWorking(currentCTC, expectedSalary);

    //Calculate time to recover:
    calculateTimeToRecoverProgramFees(priceOfProgram, expectedSalary);
    const perMonthSalary = expectedSalary / 12;
    timeToRecoverProgramFees =
      Math.ceil(priceOfProgram / perMonthSalary) || timeToRecoverProgramFees;

    //Calculate monthly EMI:
    const { monthlyEMIFloating } = calculateMonthlyEMI(
      emiDuration,
      priceOfProgram,
    );

    //CALCULATE ROI:
    calculateROIPercentageNonWokring(
      expectedSalary,
      monthlyEMIFloating,
      emiDuration,
    );
  }

  return {
    newCTC,
    timeToRecoverProgramFees,
    monthlyEMI,
    roiPercentage,
    currentCTC,
  };
};

export const performCalculationsMobile = (data) => {
  if (
    data.currentWorkingStatus === 'working' &&
    isValidField('currentCTC', Number(data.currentCTC)) &&
    isValidField('priceOfProgram', Number(data.priceOfProgram)) &&
    isValidField('hikePercentageMobile', Number(data.hikePercentageMobile)) &&
    isValidField('emiDurationMobile', Number(data.emiDurationMobile))
  ) {
    //Calculate hiked CTC:
    const currentCTC = Number(data.currentCTC);
    const hikePercentage = Number(data.hikePercentageMobile);

    const salaryHikeAmount = hikePercentage * 0.01 * currentCTC;
    const salaryAfterHike = currentCTC + salaryHikeAmount;
    const salaryAfterHikeInLakhs = (salaryAfterHike / 100000).toFixed(1);
    newCTC = Number(salaryAfterHikeInLakhs) ? salaryAfterHikeInLakhs : newCTC;

    //Calculate Time to Recover fees:
    const priceOfProgram = Number(data.priceOfProgram);

    const perMonthSalaryHike = salaryHikeAmount / 12;
    timeToRecoverProgramFees =
      Math.ceil(priceOfProgram / perMonthSalaryHike) ||
      timeToRecoverProgramFees;

    //Calcaulte Monthly EMI:
    const emiDuration = Number(data.emiDurationMobile);

    const interestFactor = EMI_MONTH_INTEREST_MAPPING[emiDuration];

    monthlyEMI =
      emiDuration === 0
        ? priceOfProgram
        : parseInt(
            (priceOfProgram * interestFactor + priceOfProgram) / emiDuration,
          ) || monthlyEMI;
  } else if (
    (data.currentWorkingStatus === 'notWorking' ||
      data.currentWorkingStatus === 'graduated') &&
    isValidField('priceOfProgram', Number(data.priceOfProgram)) &&
    isValidField('expectedSalary', Number(data.expectedSalary))
  ) {
    //Calculate Hiked CTC:
    const priceOfProgram = Number(data.priceOfProgram);
    const expectedSalary = Number(data.expectedSalary);
    const expectedSalaryInLakhs = (expectedSalary / 100000).toFixed(1);
    newCTC = Number(expectedSalaryInLakhs) ? expectedSalaryInLakhs : newCTC;

    //Calculate Time To Recover Fees:
    const perMonthSalary = expectedSalary / 12;
    timeToRecoverProgramFees =
      Math.ceil(priceOfProgram / perMonthSalary) || timeToRecoverProgramFees;

    const emiDuration = Number(data.emiDurationMobile);

    const interestFactor = EMI_MONTH_INTEREST_MAPPING[emiDuration];

    monthlyEMI =
      emiDuration === 0
        ? priceOfProgram
        : parseInt(
            (priceOfProgram * interestFactor + priceOfProgram) / emiDuration,
          ) || monthlyEMI;
  }

  return { newCTC, timeToRecoverProgramFees, monthlyEMI, currentCTC };
};
