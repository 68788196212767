import React from 'react';
import Dropdown from './Dropdown/index';
import TextField from './TextField/index';
import SliderInput from './Slider/index';
import { useFormContext } from 'react-hook-form';

/**
 * InputFields component renders different types of input fields based on the inputType prop.
 * @param {object} props - Props for configuring the input field.
 * @returns {JSX.Element} Input field component based on the inputType prop.
 * Props:
 * - inputType: Type of input field (dropdown, textfield, slider)
 * - label: Label text for the input field
 * - placeholder: Placeholder text for the input field (only for textfield)
 * - dropdownValues: Array of values for dropdown options (only for dropdown)
 * - defaultValue: Default value for the input field
 * - minValue: Minimum value for the input field (only for slider)
 * - maxValue: Maximum value for the input field (only for slider)
 * - step: Step value for the input field (only for slider)
 * - name: Name of the input field (used in form)
 * - prefixText: Prefix text for the input field (only for textfield)
 */
export default function InputFields(props) {
  const { control } = useFormContext();

  /**
   * Returns the appropriate input field component based on the inputType prop.
   * @returns {JSX.Element} Input field component
   */
  const getInputByType = () => {
    const { inputType, ...rest } = props;
    switch (inputType) {
      case 'dropdown':
        return <Dropdown control={control} {...rest} />;
      case 'textfield':
        return <TextField control={control} {...rest} />;
      case 'slider':
        return <SliderInput control={control} {...rest} />;
      default:
        return null;
    }
  };

  return <>{getInputByType()}</>;
}
