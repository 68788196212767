import Layout from '@components/v5/layouts/Layout/index';
import React from 'react';
import ROICalculatorHeader from './Header/index';
import ROICalculatorBody from './Body/index';

/**
 * ROICalculator component
 * @returns {JSX.Element} ROICalculator component
 */
export default function ROICalculator() {
  return (
    // Main layout container for the ROI calculator
    <Layout className="container flex flex-col gap-[20px] pt-[134px] md:gap-[26px] md:pb-20 md:pt-[180px]">
      {/* Header component for the ROI calculator */}
      <ROICalculatorHeader />

      {/* Body component for the ROI calculator */}
      <ROICalculatorBody />
    </Layout>
  );
}
