import React from 'react';
import * as yup from 'yup';

export const ROI_HEADER = {
  heading: (
    <>
      Return on Investment <br className="block sm:hidden" />
      (ROI) Calculator
    </>
  ),
  subHeading: (
    <>
      Find out the profit returns on your <br className="block sm:hidden" />
      investment
    </>
  ),
  headingStyle: `text-[22px] font-extrabold leading-[33px] md:text-[44px] md:leading-normal`,
  subHeadingStyle: ``,
};

export const INDUSTRY_TRENDS_HEADER = {
  heading: <>Salary Insights</>,
  subHeading: (
    <>Explore valuable salary insights based on your years of experience</>
  ),
  headingStyle: `md:text-[22px] md:leading-none text-black text-[16px] leading-[12px] font-bold`,
  subHeadingStyle: `text-neutral-v5-500`,
};
export const INPUT_FIELDS = [
  {
    id: 1,
    label: 'Are You Currently Working',
    name: 'currentWorkingStatus',
    inputType: 'dropdown',
    viewOptions: ['mobile', 'desktop'],
    dropdownType: ['working', 'notWorking', 'graduated'],
    defaultValue: 'working',
    dropdownStyle:
      'focus:bg-v5-neutral-200 md:text-[18px] text-[16px] leading-none text-v5-neutral-500 focus:text-v5-neutral-500 font-manrope h-[70px] font-bold md:pl-[24px] md:pr-[14px]',
    dropdownValues: [
      {
        label: 'Yes, I Am Working',
        value: 'working',
      },
      {
        label: 'Not Working',
        value: 'notWorking',
      },
      {
        label: 'Just Graduated',
        value: 'graduated',
      },
    ],
  },
  {
    id: 2,
    label: 'Enter Your Current CTC',
    dropdownType: ['working'],
    viewOptions: ['mobile', 'desktop'],
    name: 'currentCTC',
    inputType: 'textfield',
    placeholder: '8,00,000',
    defaultValue: '800000',
    prefixText: '₹',
    minValue: 100000,
    maxValue: 20000000,
  },
  {
    id: 3,
    label: 'Price of Program',
    dropdownType: ['working', 'notWorking', 'graduated'],
    viewOptions: ['mobile', 'desktop'],
    name: 'priceOfProgram',
    inputType: 'textfield',
    placeholder: '2,60,000',
    defaultValue: '260000',
    prefixText: '₹',
    minValue: 1,
    maxValue: 300000,
  },
  {
    id: 4,
    label: 'Expected Salary',
    name: 'expectedSalary',
    dropdownType: ['notWorking', 'graduated'],
    inputType: 'textfield',
    viewOptions: ['mobile', 'desktop'],
    placeholder: '15,20,000',
    prefixText: '₹',
    step: 10,
    minValue: 500000,
    maxValue: 10000000,
  },
  {
    id: 5,
    label: 'Expected Hike Percentage',
    name: 'hikePercentage',
    dropdownType: ['working'],
    viewOptions: ['desktop'],
    inputType: 'slider',
    mobileView: false,
    placeholder: '90',
    defaultValue: '90',
    step: 10,
    minValue: 10,
    maxValue: 150,
  },
  {
    id: 6,
    label: 'EMI Duration (in Months)',
    dropdownType: ['working', 'notWorking', 'graduated'],
    viewOptions: ['desktop'],
    inputType: 'slider',
    name: 'emiDuration',
    placeholder: '6',
    defaultValue: '6',
    step: 6,
    minValue: 0,
    maxValue: 36,
  },
  {
    id: 7,
    label: 'Expected Hike Percentage',
    name: 'hikePercentageMobile',
    dropdownType: ['working'],
    inputType: 'textfield',
    viewOptions: ['mobile'],
    placeholder: '90',
    defaultValue: '90',
    minValue: 10,
    maxValue: 150,
    multiple: 10,
  },
  {
    id: 8,
    label: 'EMI Duration (In Months)',
    dropdownType: ['working', 'notWorking', 'graduated'],
    viewOptions: ['mobile'],
    inputType: 'textfield',
    name: 'emiDurationMobile',
    placeholder: '6',
    defaultValue: '6',
    minValue: 0,
    maxValue: 36,
    multiple: 6,
  },
];

const EMI_MONTHS = {
  MONTH_0: 0,
  MONTH_6: 6,
  MONTH_12: 12,
  MONTH_18: 18,
  MONTH_24: 24,
  MONTH_30: 30,
  MONTH_36: 36,
};

export const EMI_MONTH_INTEREST_MAPPING = {
  [EMI_MONTHS.MONTH_0]: 0,
  [EMI_MONTHS.MONTH_6]: 0,
  [EMI_MONTHS.MONTH_12]: 0,
  [EMI_MONTHS.MONTH_18]: 0.07125,
  [EMI_MONTHS.MONTH_24]: 0.14,
  [EMI_MONTHS.MONTH_30]: 0.1875,
  [EMI_MONTHS.MONTH_36]: 0.2325,
};

export const SALARY_HIKE_STATUS = [
  {
    icon: 'SalaryHike',
    label:
      'Crio Learners have received an average <strong>salary hike of 81%</strong>',
  },
  {
    icon: 'Payback',
    label:
      'Payback Period is calculated based on your salary hike after Crio Program.',
  },
];

export const ROI_INPUT_SCHEMA = {
  currentCTC: yup
    .number('Please enter a valid input')
    .typeError('Please enter a valid input')
    .required('Please enter a valid input')
    .min(100000, 'Please enter a valid input(min value ₹1,00,000)')
    .max(20000000, 'Please enter a valid input (max value ₹20,000,000'),
  priceOfProgram: yup
    .number('Please enter a valid input')
    .typeError('Please enter a valid input')
    .required('Please enter a valid input')
    .min(1, 'Please enter a valid input (min value ₹1)')
    .max(300000, 'Please enter a valid input (max value ₹3,00,000)'),
  expectedSalary: yup
    .number('Please enter a valid input')
    .typeError('Please enter a valid input')
    .required('Please enter a valid input')
    .min(500000, 'Please enter a valid input (min value ₹5,00,000)')
    .max(10000000, 'Please enter a valid input (max value ₹10,000,000)'),
  hikePercentageMobile: yup
    .number('Please enter a valid input')
    .typeError('Please enter a valid input')
    .required('Please enter a valid input')
    .min(10, 'Please enter a valid input (min value 10)')
    .max(150, 'Please enter a valid input (max value 150)')
    .test(
      'is-multiple-of-10',
      'Hike Percentage must be a multiple of 10',
      (value) => value % 10 === 0,
    ),
  emiDurationMobile: yup
    .number('Please enter a valid input')
    .typeError('Please enter a valid input')
    .required('Please enter a valid input')
    .min(0, 'Please enter a valid input (min value 0)')
    .max(36, 'Please enter a valid input (max value 36)')
    .test(
      'is-multiple-of-six',
      'EMI duration must be a multiple of 6',
      (value) => value % 6 === 0,
    ),
};

export const ROI_INPUT_DEFAULTS = {
  currentWorkingStatus: 'working',
  hikePercentage: ['90'],
  emiDuration: ['6'],
  currentCTC: '',
  priceOfProgram: '',
  emiDurationMobile: '',
  hikePercentageMobile: '',
};

export const VALIDATION_TRIGGERS = {
  DESKTOP: {
    working: ['currentCTC', 'priceOfProgram'],
    nonWorking: ['priceOfProgram', 'expectedSalary'],
  },
  MOBILE: {
    working: [
      'currentCTC',
      'emiDurationMobile',
      'hikePercentageMobile',
      'priceOfProgram',
    ],
    nonWorking: ['expectedSalary', 'emiDurationMobile', 'priceOfProgram'],
  },
};

export const SKILL_BASED_SALARY_INSIGHT = [
  {
    iconStyle: 'mt-1 bg-v5-lime-300',
    heading: 'IT roles without core dev work',
    subHeading: '(1-3 years)',
    range: '₹ 3,00,000 - ₹ 17,50,000',
  },
  {
    iconStyle: 'mt-1 bg-v5-green-300',
    heading: 'Dev roles with medium skill level',
    subHeading: '(3-5 years)',
    range: '₹ 7,20,000 - ₹ 30,00,000',
  },
  {
    iconStyle: 'mt-1 bg-v5-yellow-200',
    heading: 'Dev roles with high skill level',
    subHeading: '(5-10 years)',
    range: '₹ 11,10,000 - ₹ 40,00,000',
  },
];

export const GRAPH_INSIGHT_BAR = [
  {
    heading: (
      <>
        Salary growth <br className="block md:hidden" />
        with Crio Support
      </>
    ),
    iconStyle: 'bg-v5-green-300 mt-0 md:h-3 h-1.5 w-[15px] md:w-[30px]',
    headingStyle:
      'md:text-xs text-[8px] font-rubik font-medium text-v5-neutral-500',
    wrapperClassName: 'mb-0 items-center gap-2.5',
  },
  {
    wrapperClassName: 'mb-0 items-center gap-2.5',
    heading: (
      <>
        Salary without <br className="block md:hidden" /> Crio Support
      </>
    ),
    iconStyle: 'bg-v5-neutral-300 mt-0  md:h-3 h-1.5 w-[15px] md:w-[30px]',
    headingStyle: 'md:text-xs text-[8px] font-medium text-v5-neutral-500',
  },
];

export const IND_TRENDS_DROP_OPTIONS = [
  {
    label: 'For 1-3 years work experience',
    value: '1_3',
  },
  {
    label: 'For 3-5 years work experience',
    value: '3_5',
  },
  {
    label: 'For 5-10 years work experience',
    value: '5_10',
  },
];
