import React, { useState } from 'react';
import Layout from '@components/v5/layouts/Layout/index';
import ROIInputContainer from './InputContainer/index';
import ROIResult from './Result/index';

/**
 * ROICalculatorBody component
 * @returns {JSX.Element} ROICalculatorBody component
 */
export default function ROICalculatorBody() {
  const [result, setResult] = useState({
    newCTC: 15.2,
    timeToRecoverProgramFees: 5,
    monthlyEMI: 5000,
  });

  return (
    <Layout className="flex flex-col gap-[38px] rounded-[10px] pb-8 md:flex-row md:gap-[50px] md:bg-v5-neutral-100 md:pl-[50px] md:pr-10 md:pt-[50px] xl:gap-[70px]">
      <ROIInputContainer setResult={setResult} />
      <ROIResult result={result} />
    </Layout>
  );
}
