import InsightBarLayout from '@components/ROI/Layouts/InsightBar/index';
import { SKILL_BASED_SALARY_INSIGHT } from '@src/constants/ROI/index';
import React from 'react';

export default function SalaryInsightBody() {
  return (
    <div className="">
      {SKILL_BASED_SALARY_INSIGHT.map((data) => (
        <InsightBarLayout {...data} />
      ))}
    </div>
  );
}
