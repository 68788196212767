import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// Importing components
import FooterV2 from '@components/FooterV2/index';
import NavigationV2 from '@components/NavigationV2/index';
import ROICalculator from '@components/ROI/Calculator/index';
import RoundedLayout from '@components/v5/layouts/RoundedLayout/index';
import SalaryGrowthVisualization from '@components/ROI/SalaryGrowthVisualization/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import SkillBasedSalaryGrowth from '@components/ROI/SkillBasedSalaryGrowth/index';

// Importing constants
import { LEAD_GEN_ROI_SALARY_VISUALIZATION } from '@src/constants/LeadGenerationConstants/index';
import { ROI_INPUT_DEFAULTS, ROI_INPUT_SCHEMA } from '@src/constants/ROI/index';

// Setting up Yup schema for form validation
const schema = yup.object(ROI_INPUT_SCHEMA);

/**
 * ROIPage component
 * @returns {JSX.Element} ROIPage component
 */
const ROIPage = () => {
  // Initializing useForm hook with default values and Yup resolver
  const methods = useForm({
    defaultValues: ROI_INPUT_DEFAULTS, // Default form values
    mode: 'all', // Validation mode
    resolver: yupResolver(schema), // Yup resolver for form validation
  });

  return (
    <>
      {/* Navigation component */}
      <NavigationV2 />

      {/* Main content wrapped in a rounded layout */}
      <RoundedLayout
        roundedBg="bg-v5-green-100"
        className="bg-v5-neutral-200 pb-20"
      >
        {/* Providing form context to child components */}
        <FormProvider {...methods}>
          {/* ROI Calculator component */}
          <ROICalculator />

          {/* Salary Growth Visualization component */}
          <SalaryGrowthVisualization />

          {/* Lead Generation Button component */}
          <LeadGenerationButton
            wrapperClassName="flex justify-center md:pb-20 pb-14"
            type="Apply & Start Free"
            buttonLocation={LEAD_GEN_ROI_SALARY_VISUALIZATION} // Button location constant
          />

          {/* Skill Based Salary Growth component */}
          <SkillBasedSalaryGrowth />
        </FormProvider>
      </RoundedLayout>

      {/* Footer component */}
      <FooterV2 />
    </>
  );
};

export default ROIPage;
