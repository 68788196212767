import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import RawHTML from '@components/Common/RawHTML/index';

export default function Stat({ icon, label }) {
  const data = useStaticQuery(graphql`
    query {
      salaryStats: allFile(filter: { relativePath: { regex: "images/ROI/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `);

  const salaryStats = data.salaryStats.nodes;

  const iconImageData = salaryStats.find(({ name }) => name === icon);
  const image = getImage(iconImageData?.childImageSharp);

  return (
    <div className="flex gap-5">
      <div>
        <GatsbyImage
          loading="lazy"
          image={image}
          placeholder="blurred"
          alt={'Stat Icon'}
          style={{ width: '20px', marginTop: '4px' }}
        />
      </div>
      <RawHTML className="font-rubik text-[12px] font-normal text-v5-neutral-400 md:text-base">
        {label}
      </RawHTML>
    </div>
  );
}
