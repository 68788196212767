import React from 'react';

export default function CustomDot({ cx, cy, stroke, active }) {
  return (
    <circle
      cx={cx}
      cy={cy}
      r={active ? 5 : 3}
      fill="#fff"
      stroke={stroke}
      strokeWidth={active ? 3 : 2}
    />
  );
}
