import React, { useEffect } from 'react';
import Layout from '@components/v5/layouts/Layout/index';
import { useFormContext } from 'react-hook-form';
import useResizer from '@components/extra/useResizer';
import { ROI_INPUT_DEFAULTS } from '@src/constants/ROI/index';
import { performCalculations, resetToDefauls } from '@src/utils/ROI/roi';
import ROIInsightsBox from './InsightsBox/index';

export default function CalculationResult({ result }) {
  const isMobile = useResizer();
  const {
    watch,
    formState: { errors },
    reset,
  } = useFormContext();

  const data = watch();
  const { newCTC, timeToRecoverProgramFees, monthlyEMI } = result || {}; // Destructure result if available

  useEffect(() => {
    reset({
      ...ROI_INPUT_DEFAULTS,
      currentWorkingStatus: data.currentWorkingStatus,
    });
    resetToDefauls(data.currentWorkingStatus);
  }, [data.currentWorkingStatus, reset, isMobile]);

  // Define a function to render the insights boxes
  const renderInsightsBoxes = ({
    newCTC,
    timeToRecoverProgramFees,
    monthlyEMI,
  }) => (
    <>
      <ROIInsightsBox
        label="Your New CTC After Salary Hike"
        value={`₹ ${newCTC} Lakhs`}
      />
      <ROIInsightsBox
        label="Time to Recover Program Fee"
        value={`${timeToRecoverProgramFees} months`}
      />
      <ROIInsightsBox label="Monthly EMI Value" value={`₹ ${monthlyEMI}`} />
    </>
  );

  return (
    <Layout className="px-6 pt-2 md:px-8 lg:px-12">
      {isMobile
        ? // Render insights boxes with provided result if mobile
          renderInsightsBoxes({ newCTC, timeToRecoverProgramFees, monthlyEMI })
        : // Perform calculations only if not mobile
          renderInsightsBoxes(performCalculations(data, errors))}
    </Layout>
  );
}
