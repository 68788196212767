import * as React from 'react';

import { cn } from '@/lib/utils';

const Input = React.forwardRef(
  ({ className, type, prefixText, ...props }, ref) => {
    return (
      <div className="relative">
        {prefixText && (
          <span className="absolute inset-y-0 left-0 flex items-center pl-4 text-base font-bold leading-none text-v5-neutral-500 md:text-lg">
            {prefixText}
          </span>
        )}
        <input
          type={type}
          className={cn(
            ' flex h-10 w-full rounded-md border border-zinc-200 bg-white py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-zinc-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
            className,
          )}
          ref={ref}
          {...props}
        />
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input };
