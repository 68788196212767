import HeaderLayout from '@components/ROI/Layouts/Header/index';
import { ROI_HEADER } from '@src/constants/ROI/index';
import React from 'react';

/**
 * ROIHeader component
 * @returns {JSX.Element} ROIHeader component
 */
export default function ROIHeader() {
  return (
    <HeaderLayout
      content={ROI_HEADER} // ROI_HEADER represents the content for the ROI header, containing heading, headingStyle, subHeading and subHeadingStyle
      className="gap-5 px-3 md:gap-[26px]"
    />
  );
}
