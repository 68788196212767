import React from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import InputLayout from '@components/ROI/Layouts/Input/index';
import { Controller } from 'react-hook-form';
import { cn } from '@/lib/utils';

/**
 * Dropdown component renders a dropdown input field.
 * @param {object} props - Props for configuring the dropdown field.
 * @returns {JSX.Element} Dropdown input field component.
 * Props:
 * - label: Label text for the dropdown field.
 * - dropdownValues: Array of objects containing label and value for dropdown options.
 * - defaultValue: Default value for the dropdown field.
 * - name: Name of the dropdown field (used in form).
 * - control: Control object provided by useFormContext() from react-hook-form.
 */
export default function Dropdown({
  label,
  dropdownValues,
  defaultValue,
  name,
  control,
  className,
  dropdownStyle,
  triggerStyle,
  dropdownIconStyle,
}) {
  return (
    <InputLayout label={label} className={className}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            defaultValue={defaultValue}
            {...field}
            onValueChange={field.onChange}
          >
            <SelectTrigger
              className={cn(
                'h-[60px] w-full border-[1px] border-solid border-v5-green-200 bg-v5-neutral-200 font-manrope text-[16px] font-bold text-v5-neutral-500 focus:outline-none md:text-[18px]',
                triggerStyle,
              )}
              dropdownIconStyle={dropdownIconStyle}
            >
              <SelectValue placeholder="" />
            </SelectTrigger>
            <SelectContent className=" z-[2000]">
              {dropdownValues.map(({ label, value }) => (
                <SelectItem key={value} value={value} className={dropdownStyle}>
                  {label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
      />
    </InputLayout>
  );
}
