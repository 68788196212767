import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function SalaryInsightGraph() {
  return (
    <div className="max-w-[750px]">
      <StaticImage
        src="../../../../images/ROI/SkillBasedSalaryGraph.png"
        objectFit="cover"
      />
    </div>
  );
}
