import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useDialog } from '@components/v5/Dialog/index';

export default function CloseModal() {
  const [_, closeModal] = useDialog();
  return (
    <div className="absolute top-3 right-2 hidden cursor-pointer rounded-[50px] bg-v5-green-1000 px-4 py-2 md:block">
      <FontAwesomeIcon
        icon={faXmark}
        className="text-v5-green-700"
        onClick={closeModal}
      />
    </div>
  );
}
