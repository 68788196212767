import React from 'react';

export default function SalaryInsightHeader() {
  return (
    <div className="px-10 text-center font-manrope text-[18px] font-bold leading-normal text-v5-neutral-500 md:px-0 md:text-left md:text-[20px]">
      Salary growth based on <br />
      skill level
    </div>
  );
}
