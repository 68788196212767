import React from 'react';
import { Input } from '@/components/ui/input';
import { Controller, useFormContext } from 'react-hook-form';
import InputLayout from '@components/ROI/Layouts/Input/index';
import ErrorLayout from '@components/ROI/Layouts/Error/index';

/**
 * TextField component renders a text input field.
 * @param {object} props - Props for configuring the text field.
 *                          label: The label for the input field.
 *                          placeholder: Placeholder text for the input field.
 *                          name: The name of the input field.
 *                          control: Form control instance from useForm.
 *                          prefixText: Optional prefix text to display before the input field.
 * @returns {JSX.Element} Text input field component.
 */
export default function TextField({
  label,
  placeholder,
  name,
  control,
  prefixText,
}) {
  // Access form state and methods from useFormContext
  const { formState } = useFormContext();

  return (
    <InputLayout label={label}>
      {/* Controller component to connect input field with react-hook-form
       * Allows easy integration of react hook forms with external controlled component like shadcn(in this case), mui..etc
       */}
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Input
              type="text"
              placeholder={placeholder}
              // Dynamic class names based on props and form state
              className={`h-[60px] w-full border-[1px] border-solid border-v5-neutral-700  font-manrope text-base font-bold text-v5-neutral-500 placeholder:text-v5-neutral-300 focus:outline-none md:text-lg ${
                prefixText ? 'pl-10' : 'pl-5'
              } ${
                formState?.errors[name] ? 'border-v5-red-100 bg-v5-red-300' : ''
              }`}
              prefixText={prefixText} // Prefix text for the input
              {...field} // Spread field props from Controller
            />
          );
        }}
      />
      {/* Display error layout if there are errors for the input */}
      {formState?.errors[name] && (
        <ErrorLayout error={formState?.errors[name]?.message} />
      )}
    </InputLayout>
  );
}
