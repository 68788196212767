import Layout from '@components/v5/layouts/Layout/index';
import React from 'react';
import Header from './Header/index';
import SalaryHikeStat from './SalaryHikeStat/index';
import CalculationResult from './CalculationResult/index';
import EMIDisclaimer from './EMIDisclaimer/index';

/**
 * ROIResult component renders the result section of the ROI calculator.
 * It includes the header, calculation result, salary hike statistics, and EMI disclaimer.
 * @returns {JSX.Element} ROIResult component
 */
export default function ROIResult({ result }) {
  return (
    <Layout className="w-full rounded-[10px] bg-v5-neutral-100 shadow-100 md:bg-none">
      <Header />
      <CalculationResult result={result} />
      <SalaryHikeStat />
      <EMIDisclaimer />
    </Layout>
  );
}
