import { cn } from '@/lib/utils';
import RawHTML from '@components/Common/RawHTML/index';
import React from 'react';

export default function NoteLayout({ children, className, note }) {
  return (
    <div
      className={cn(
        'border-l-4 border-l-v5-green-900 pl-4 font-rubik text-xs font-normal text-v5-neutral-400',
        className,
      )}
    >
      <span className="font-bold">Note</span>:{' '}
      <RawHTML className="inline">{note}</RawHTML>
    </div>
  );
}
