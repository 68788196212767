import NoteLayout from '@components/ROI/Layouts/Note/index';
import Layout from '@components/v5/layouts/Layout/index';
import React from 'react';

export default function EMIDisclaimer() {
  return (
    <Layout className="px-6 pt-6 pb-6 md:px-8 lg:px-12">
      <NoteLayout note="The <strong>monthly EMI </strong> is subjected to variations depending on the NBFC partner selected" />
    </Layout>
  );
}
