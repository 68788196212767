import Layout from '@components/v5/layouts/Layout/index';
import React from 'react';
import EMIDisclaimer from '@src/components/ROI/Layouts/Note/index';
import SalaryGrowthGraph from '@src/components/ROI/SalaryGrowthVisualization/SalaryGrowthGraph';
import ROIPercentage from '@src/components/ROI/SalaryGrowthVisualization/ROIPercentage';

export default function SalaryGrowthVisualization() {
  return (
    <Layout className="container grid items-center gap-x-14 gap-y-5 pb-14 md:grid-cols-[2fr_1fr] md:px-14 md:pb-20">
      <SalaryGrowthGraph />
      <ROIPercentage />
      <EMIDisclaimer
        className="col-span-full ml-2 md:ml-5"
        note={
          <>
            We have considered a conservative 12% year on year increase in
            salary for both scenarios. The variation arises from the salary
            boost you immediately get following Crio Graduation. The Return on
            Investment is calculated based on your potential earnings over the
            next five years.
            <br></br>
            <strong className="">
              Based on placement data of Crio Graduates till Dec 2023*
            </strong>
          </>
        }
      />
    </Layout>
  );
}
