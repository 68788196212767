import React from 'react';
import SkillBasedSalaryInsight from './SalaryInsight/index';
import Layout from '@components/v5/layouts/Layout/index';
import SalaryInsightGraph from './InsightGraph/index';
import IndustryTrends from '../IndustryTrends/index';

export default function SkillBasedSalaryGrowth() {
  return (
    <Layout className="container pt-0 md:flex-row">
      <div className="flex flex-col justify-between gap-6 rounded-[10px] bg-white px-3 pt-7 md:flex-row md:gap-10 md:px-9 md:py-9">
        <SkillBasedSalaryInsight />
        <SalaryInsightGraph />
        <div className="block md:hidden">
          <IndustryTrends />
        </div>
      </div>
    </Layout>
  );
}
