import InsightBarLayout from '@components/ROI/Layouts/InsightBar/index';
import React from 'react';

export default function CustomTooltip({ active, payload, label }) {
  if (active && payload && payload.length) {
    return (
      <div className="rounded-[6px] border border-solid bg-v5-neutral-900 p-[10px]">
        <InsightBarLayout
          heading={`${payload[0].value}L`}
          iconStyle="bg-v5-green-300 mt-0"
          headingStyle="md:text-[18px] text-[16px] font-bold font-manrope"
          wrapperClassName="gap-2 items-center mb-2"
        />
        <InsightBarLayout
          heading={`${payload[1].value}L`}
          iconStyle="bg-v5-neutral-300 mt-0"
          headingStyle="md:text-[18px] text-[16px] font-bold font-manrope"
          wrapperClassName="gap-2 items-center mb-0"
        />
      </div>
    );
  }
}
