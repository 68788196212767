import React from 'react';
import styled from 'styled-components';
import Stat from './Stat/index';
import { SALARY_HIKE_STATUS } from '@src/constants/ROI/index';
import Layout from '@components/v5/layouts/Layout/index';

const StyledDiv = styled.div`
  width: 100px;
  height: 100px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%230093781A' stroke-width='5' stroke-dasharray='6%2c 16' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 10px;
`;

export default function SalaryHikeStat() {
  return (
    <Layout className="px-6 pt-2 md:px-8 lg:px-12">
      {/* Use the styled component */}
      <StyledDiv className="mx mt-6 flex h-auto w-full flex-col gap-5 px-8 py-5">
        {SALARY_HIKE_STATUS.map(({ icon, label }) => (
          <Stat key={icon} icon={icon} label={label} />
        ))}
      </StyledDiv>
    </Layout>
  );
}
