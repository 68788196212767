import React from 'react';

/**
 * Header component renders the header section of the ROI calculator result.
 * @returns {JSX.Element} Header component
 */
export default function Header() {
  return (
    <div className="hidden rounded-t-[10px] bg-v5-green-800 py-6 px-7 text-center font-manrope text-[14px] font-bold leading-5 text-v5-neutral-500 md:block md:text-base md:leading-none">
      Input different Hike Percentage Values to play out different scenarios
    </div>
  );
}
