import { cn } from '@/lib/utils';
import React from 'react';

export default function ButtonLayout({ btnText, onClick, className }) {
  return (
    <button
      className={cn(
        'h-16 rounded-[10px] bg-v5-yellow-200 text-center text-lg font-bold leading-none text-v5-neutral-500',
        className,
      )}
      onClick={onClick}
    >
      {btnText}
    </button>
  );
}
