import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useFormContext } from 'react-hook-form';
import { performCalculations } from '@src/utils/ROI/roi';
import { cn } from '@/lib/utils';

export default function ROIPercentage() {
  const { watch } = useFormContext();
  const data = watch();

  const { roiPercentage } = performCalculations(data);
  return (
    <div className="relative hidden md:block">
      <StaticImage
        src="../../../../images/ROI/ROIPercentImg.png"
        loading="lazy"
        className=""
        objectFit="cover"
      />
      {roiPercentage >= 0 && roiPercentage !== Infinity && (
        <>
          <StaticImage
            src="../../../../images/ROI/roiBubble.png"
            loading="lazy"
            className="absolute top-[-20px] right-[-20px] max-w-[160px]"
            objectFit="cover"
          />
          <div
            className={cn(
              'absolute top-1 flex flex-col items-center text-center font-manrope',
              roiPercentage > 1000 ? 'right-0' : 'right-4',
            )}
          >
            <div className="text-[8px] leading-3 text-v5-neutral-500">
              Return On Investment
            </div>
            <div className="text-[32px] font-extrabold leading-none text-v5-green-500">
              {roiPercentage > 1000 ? `>1000` : roiPercentage}%
            </div>
            <div></div>
          </div>
        </>
      )}
    </div>
  );
}
