import { cn } from '@/lib/utils';
import React from 'react';

export default function InputLayout({ label, children, className, inputBox }) {
  return (
    <div
      className={cn(
        'flex flex-col gap-2.5 font-manrope text-base font-semibold leading-normal md:gap-2 md:font-medium md:leading-7',
        className,
      )}
    >
      <div className="flex justify-between">
        <label className="text-v5-neutral-500">{label}</label>
        {inputBox}
      </div>
      {children}
    </div>
  );
}
