import React, { useEffect, useState } from 'react';
import { Slider } from '@/components/ui/slider';
import InputLayout from '@components/ROI/Layouts/Input/index';
import { Input } from '@/components/ui/input';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { VALIDATION_TRIGGERS } from '@src/constants/ROI/index';

/**
 * SliderInput component renders a slider input field.
 * @param {object} props - Props for configuring the slider input field.
 *                          label: The label for the input field.
 *                          defaultValue: The default value for the slider.
 *                          minValue: The minimum value for the slider.
 *                          maxValue: The maximum value for the slider.
 *                          step: The step value for the slider.
 *                          name: The name of the input field.
 *                          control: Form control instance from useForm.
 * @returns {JSX.Element} Slider input field component.
 */
export default function SliderInput({
  label,
  defaultValue,
  minValue,
  maxValue,
  step,
  name,
  control,
}) {
  // State to manage current value of the slider
  // keeps input box in sync with the slider value.
  const [value, setValue] = useState(defaultValue);

  // Access form state and methods from useFormContext
  const {
    formState: { errors },
    trigger,
    watch,
  } = useFormContext();

  // Watch for changes in currentWorkingStatus field
  const { currentWorkingStatus } = watch();

  useEffect(() => {
    setValue(defaultValue);
  }, [currentWorkingStatus]);

  return (
    <InputLayout
      label={label} // Label for the input field
      className="md:gap-7"
      // Render input box with current slider value
      inputBox={
        <Input
          className="mx-auto w-[70px] border border-solid border-v5-neutral-700 border-v5-green-200 bg-v5-neutral-200 text-center font-manrope text-base font-bold text-v5-neutral-500 placeholder:text-v5-neutral-300 focus:outline-none md:text-lg"
          value={`${value === 0 ? 1 : value}${
            label.includes('Hike') ? '%' : ''
          }`} // Display value with '%' if label contains 'Hike'
          type="text"
        />
      }
    >
      {/* Controller component to connect slider with react-hook-form */}
      <Controller
        name={name}
        control={control} // Form control instance from useForm
        render={({ field }) => (
          <Slider
            defaultValue={[defaultValue]}
            max={maxValue}
            min={minValue}
            step={step}
            trackStyle="bg-v5-neutral-800" // Style for the slider track
            sliderStyle="bg-v5-green-300" // Style for the slider thumb
            thumbStyle="border-v5-green-300" // Style for the slider thumb
            // Triggers validation for relevant fields based on the current working status:
            onFocus={() => {
              if (currentWorkingStatus === 'working') {
                // If dropdown selected is 'Yes, I am working', trigger validation for 'currentCTC' and 'priceOfProgram' fields.
                trigger(VALIDATION_TRIGGERS.DESKTOP.working);
              } else {
                // Otherwise, trigger validation for 'priceOfProgram' and 'expectedSalary' fields(as only these fields are shown based on dropdown selected.)
                trigger(VALIDATION_TRIGGERS.DESKTOP.nonWorking);
              }
            }}
            // Update field value and slider value onChange
            onValueChange={(v) => {
              // Prevent onChange if there are errors
              if (!isEmpty(errors)) return;
              // Update field value
              field.onChange(v);
              // Update slider value
              setValue(v[0]);
            }}
            {...field} // Spread field props from Controller
          />
        )}
      />
    </InputLayout>
  );
}
