import React from 'react';
// import { faCircleInfo } from '@fortawesome/free-regular-svg-icons';

export default function ErrorLayout({ error, hasError }) {
  return (
    <div className="font-rubik text-xs font-normal leading-none text-v5-red-100">
      {/* <FontAwesomeIcon icon={faCircleInfo} /> */}
      <span>
        <em>{error ? error : 'Please enter valid input'}</em>
      </span>
    </div>
  );
}
