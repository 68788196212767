import React from 'react';
import SalaryInsightHeader from './Header/index';
import SalaryInsightBody from './Body/index';
import SalaryInsightFooter from './Footer/index';
import { useDialog } from '@components/v5/Dialog/index';
// import img from '../../../../images/ROI/SalaryInsightGraph';

export default function SkillBasedSalaryInsight() {
  const [openDialog, closeDialog] = useDialog();
  return (
    <div className="flex w-full flex-col items-center gap-9 md:items-start">
      <SalaryInsightHeader />
      <SalaryInsightBody />
      <SalaryInsightFooter openDialog={openDialog} />
    </div>
  );
}
