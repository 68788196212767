import Layout from '@components/v5/layouts/Layout/index';
import React from 'react';
import IndustryTrendsDisclaimer from '../Layouts/Note/index';
import IndustryTrendsHeader from '../Layouts/Header/index';
import IndustryTrendsGraph from './IndustryTrendsGraph/index';
import { INDUSTRY_TRENDS_HEADER } from '@src/constants/ROI/index';
import CloseModal from './CloseModal/index';

export default function IndustryTrends() {
  return (
    <Layout className="relative overflow-auto">
      <CloseModal />
      <IndustryTrendsHeader
        content={INDUSTRY_TRENDS_HEADER}
        className="gap-5 px-3 pb-3 md:gap-[26px]"
      />
      <IndustryTrendsGraph />
      <Layout className="ml-3 px-2 pt-6 md:px-10 lg:px-20">
        <IndustryTrendsDisclaimer note="The median indicates the average salary expected in correlation with one's years of experience" />
      </Layout>
    </Layout>
  );
}
