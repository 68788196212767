import { cn } from '@/lib/utils';
import React from 'react';

export default function InsightBarLayout({
  heading,
  subHeading,
  range,
  iconStyle,
  headingStyle,
  wrapperClassName,
}) {
  return (
    <div className={cn('mb-[24px] flex gap-4 font-rubik', wrapperClassName)}>
      <div className={cn('mt-1 h-3 w-[30px] rounded-2xl', iconStyle)}></div>
      <div className="flex flex-col gap-[10px] md:gap-4">
        <div className="flex text-v5-neutral-500 md:block">
          <div className={cn('text-sm font-normal md:text-base', headingStyle)}>
            {heading}
          </div>
          {subHeading && (
            <div className={cn('text-sm md:text-base md:font-bold')}>
              {subHeading}
            </div>
          )}
        </div>
        {range && (
          <div
            className={cn(
              'text-base font-bold leading-normal text-v5-neutral-400 md:text-lg md:leading-none',
            )}
          >
            {range}
          </div>
        )}
      </div>
    </div>
  );
}
