import React from 'react';

export default function ROIInsightsBox({ label, value }) {
  return (
    <div className="flex justify-between border-b-2 border-v5-green-800 py-7 font-manrope">
      <div className="max-w-[112px] font-semibold text-v5-neutral-500 md:max-w-none md:text-lg">
        {label}
      </div>
      <div className="text-right text-[18px] font-extrabold text-v5-green-300 md:text-[32px] md:leading-none">
        {value}
      </div>
    </div>
  );
}
