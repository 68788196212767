import { cn } from '@/lib/utils';
import React from 'react';

export default function HeaderLayout({ content, className }) {
  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center text-center font-manrope',
        className,
      )}
    >
      <div className={content.headingStyle}>{content.heading}</div>
      <div className={content.subHeadingStyle}>{content.subHeading}</div>
    </div>
  );
}
