import InputFields from '@components/ROI/InputFields/index';
import ButtonLayout from '@components/ROI/Layouts/Button/index';
import useResizer from '@components/extra/useResizer';
import { GTM } from '@src/analytics/gtm';
import { INPUT_FIELDS, VALIDATION_TRIGGERS } from '@src/constants/ROI/index';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import { performCalculationsMobile } from '@src/utils/ROI/roi';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

/**
 * ROIInputContainer component renders input fields and a calculate button for the ROI calculator.
 * @returns {JSX.Element} ROIInputContainer component
 */
export default function ROIInputContainer({ setResult }) {
  // Accessing form context for form data
  const {
    watch,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext();

  // State to manage the view option (mobile or desktop)
  const [viewOption, setViewOption] = useState();

  // Extracting the current working status from form data
  const workingStatus = watch()?.currentWorkingStatus;

  // Checking if the screen is mobile using the useResizer hook
  const isMobile = useResizer();

  // Update the view option based on the screen size
  useEffect(() => {
    setViewOption(isMobile ? 'mobile' : 'desktop');
  }, [isMobile]);

  const state = useContext(GlobalStateContext);

  // Filter the input fields based on the current working status and view option.
  // This is done to display only the input fields that are relevant to the current working status(working/notWorking/graduated) and view option (mobile or desktop).
  const filteredInputs = INPUT_FIELDS.filter(
    (input) =>
      input.dropdownType.includes(workingStatus) &&
      input.viewOptions.includes(viewOption),
  );

  return (
    <div className="flex w-full flex-col gap-[20px] rounded-[10px] bg-v5-neutral-100 px-[20px] py-[28px] md:max-w-[330px] md:bg-none md:py-0 md:px-0">
      {/* Rendering filtered input fields */}
      {filteredInputs.map((input) => (
        <InputFields key={input.id} {...input} />
      ))}
      {/* Rendering the calculate button for mobile view only */}
      <div className="flex w-full justify-center md:hidden " id="roiResult">
        <ButtonLayout
          className="w-40"
          btnText={'Calculate'}
          onClick={() => {
            const data = getValues();
            if (data.currentWorkingStatus === 'working') {
              trigger(VALIDATION_TRIGGERS.MOBILE.working);
            } else {
              trigger(VALIDATION_TRIGGERS.MOBILE.nonWorking);
            }
            // Prevent onChange if there are errors
            if (!isEmpty(errors)) return;
            setResult(performCalculationsMobile(getValues()));

            // Scroll to element with ID 'roiResultHeader'
            const element = document.getElementById('roiResult');
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }

            GTM.leadSquaredEvent(
              ELeadSquaredActivityCode.ROI_CALCULATE_CTA_CLICKED,
              state?.email,
            );
          }}
        />
      </div>
    </div>
  );
}
