import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import Dropdown from '@components/ROI/InputFields/Dropdown/index';
import { useForm } from 'react-hook-form';
import { IND_TRENDS_DROP_OPTIONS } from '@src/constants/ROI/index';

export default function IndustryTrendsGraph() {
  const { control, watch } = useForm({
    defaultValues: {
      industryTrends: '1_3',
    },
  });

  const industryTrends = watch()?.industryTrends;

  const data = useStaticQuery(graphql`
    query {
      salaryStats: allFile(filter: { relativePath: { regex: "images/ROI/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              width: 700
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `);

  const salaryStats = data.salaryStats.nodes;

  const iconImageData = salaryStats.find(({ name }) =>
    name.includes(industryTrends),
  );
  const image = getImage(iconImageData?.childImageSharp);

  return (
    <div className="flex w-full justify-center md:px-16">
      <div className="relative">
        <Dropdown
          name="industryTrends"
          control={control}
          dropdownValues={IND_TRENDS_DROP_OPTIONS}
          defaultValue="1_3"
          className="absolute  right-4 top-2 z-[999] h-[36px] md:top-4"
          dropdownStyle="z-[2000] font-rubik md:[16px] leading-normal"
          triggerStyle="bg-white border-v5-neutral-700 md:h-[36px] h-[18px] md:text-[16px] text-[8px] font-rubik font-normal md:px-3 md:gap-3 px-1 py-0 gap-1"
          dropdownIconStyle="md:h-4 md:w-4 w-2"
        />

        <GatsbyImage
          loading="lazy"
          image={image}
          placeholder="blurred"
          alt={'MEDIAN_GRAPH'}
          style={{ marginTop: '4px' }}
        />
      </div>
    </div>
  );
}
